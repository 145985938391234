const PROD = 'https://dragon.playco.games/';
const DEV = 'https://dragon-concept-dev.cdn.gc-internal.net';
// const PROD = 'https://liff.line.me/2000577972-4N7wJBQ0';
// const DEV = 'https://liff.line.me/2000577914-PjMW8N6q';
const LOCAL = 'https://localhost:8052';

export function getGameUrl() {
    let url = LOCAL; // No stage -> local dev.
    if (process.env.STAGE === 'prod') url = PROD;
    if (process.env.STAGE === 'dev') url = DEV;

    return url;
}
