import app from '../../index.entry';
import { IFlow } from '../../lib/pattern/IFlow';
import NakedPromise from '../../lib/pattern/NakedPromise';
import { forageItemIds, PetItemId, treatItemIds } from '../../replicant/defs/items';
import { sleep } from '../../replicant/util/jsTools';
import { PetHelpPopup } from '../lib/ui/popups/PetHelpPopup';
import { PetScreen } from '../main/pet/PetScreen';

type PetHelpFlowOptions = {
    petScreen: PetScreen;
    type: 'treat' | 'forage';
};

export class PetHelpFlow implements IFlow {
    private readonly _complete = new NakedPromise<boolean>();
    private _options: PetHelpFlowOptions;

    // init
    //-------------------------------------------------------------------------
    constructor(options: PetHelpFlowOptions) {
        this._options = options;
    }

    // impl
    //-------------------------------------------------------------------------
    public async execute() {
        await this._actionPetHelp();

        return this._complete;
    }

    private async _actionComplete(success: boolean) {
        this._complete.resolve(success);
    }

    private async _actionPetHelp() {
        const helpMap = {
            treat: {
                title: '[petTreat]',
                items: treatItemIds,
                limited: true,
            },
            forage: {
                title: '[petForage]',
                items: forageItemIds,
                limited: false,
            },
        };

        const exitPromise = new NakedPromise();
        const popupId = 'petHelpPopup';
        const petHelpPopup = (await app.nav.open(popupId, {
            items: helpMap[this._options.type].items,
            title: helpMap[this._options.type].title,
            isLimited: helpMap[this._options.type].limited,
            onClose: exitPromise.resolve,
        })) as PetHelpPopup;

        petHelpPopup.onItemPress = (petItemId: PetItemId) => {
            app.nav.close(popupId);
            this._itemPress(petItemId);
            exitPromise.resolve();
        };

        await exitPromise;
        app.nav.close(popupId);
        this._actionComplete(true);
    }

    private async _itemPress(petItemId: PetItemId) {
        const { type, petScreen } = this._options;
        const progress = {
            treat: petScreen.healthProgress,
            forage: petScreen.foodProgress,
        };

        app.sound.play('buff1.ogg', { volume: 0.5 });

        if (progress[type].progress !== 1) {
            setTimeout(async () => {
                // TODO REAL ANIMATION
                for (let i = 1; i <= 25; i++) {
                    progress[type].setProgress(0.75 + i * 0.01);
                    await sleep(0.012);
                }
            }, 0.5);
        }
        if (type === 'forage') {
            petScreen.stopMovement();
            // let popup close so we can see eating animation clearly
            await sleep(0.25);
            petScreen
                .playPetAnimation(petScreen.hasBlanket ? 'eat_withblanket' : 'eat')
                .then(() =>
                    petScreen.playPetAnimation(petScreen.hasBlanket ? 'idle_happy_blanket' : 'idle_happy', true),
                );

            await sleep(3);
            petScreen.isTalking = false;
            if (petScreen.flyAnimation) return;
            petScreen.startPet();
        } else if (type === 'treat' && petItemId === 'blanket') {
            petScreen.equipBlanket();
        }
    }
}
