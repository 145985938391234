import { Container, NineSlicePlane, Sprite, Texture } from 'pixi.js';

import app from '../../../index.entry';
import { SizeType } from '../../../lib/defs/types';
import NakedPromise from '../../../lib/pattern/NakedPromise';
import { uiAlignCenter, uiAlignCenterX, uiCreateQuad, uiSizeToFit } from '../../../lib/pixi/uiTools';
import { tween } from '../../../lib/util/tweens';
import { LayoutScreen3 } from '../../lib/screens/LayoutScreen3';
import { ImageButton } from '../../lib/ui/buttons/ImageButton';
import { TextImageButton } from '../../lib/ui/buttons/TextImageButton';
import { BasicText } from '../../lib/ui/text/BasicText';
import { TextInput } from '../../lib/ui/text/TextInput';

//-----------------------------------------------------------------------------
export type PetNamePopupOptions = {
    onConfirm: NakedPromise<string>;
    preFilledName: string;
};

// manifest
//-----------------------------------------------------------------------------
const manifest = {
    smallBubble: 'bubble.small.png',
    bubbleTail: 'bubble.tail.png',
    frame: 'dialogue.blue.fade.png',
    input: 'frame.input.png',
    blueButton: 'button.blue.png',
    bigBubble: 'bubble.large.png',
};

/*
    Transparent overlay popup, used for tutorial pet name
*/
export class PetNamePopup extends LayoutScreen3 {
    // fields
    //-------------------------------------------------------------------------
    private _input: TextInput;
    private _confirmButton: ImageButton;
    private _inputContainer: Container;

    // properties
    //-------------------------------------------------------------------------
    public get confirmButton() {
        return this._confirmButton;
    }

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([...Object.values(manifest)]);
    }

    public async spawning(options: PetNamePopupOptions) {
        // spawn scene
        this._spawn(options);
    }

    public async despawned() {
        this.empty();
        this._input.stop();
    }

    public async despawning() {
        await this.animateOut();
    }

    public async spawned(): Promise<void> {
        this._input.start();
        await this.animateIn();
    }

    public override resized(size: SizeType): void {
        super.resized(size);
        uiAlignCenterX(size, this._inputContainer);
        this._inputContainer.y = size.height - 300;
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn(options: PetNamePopupOptions) {
        const topFrame = Sprite.from(manifest.frame);

        const textWidth = topFrame.width - 160;
        const mainLabel = new BasicText({
            text: '[petTutorialMain]',
            style: {
                fill: '#FFF',
                fontSize: 32,
                lineJoin: 'round',
                wordWrap: true,
                lineHeight: 55,
                wordWrapWidth: textWidth,
            },
        });

        topFrame.addChild(mainLabel);
        uiAlignCenter(topFrame, mainLabel);
        uiSizeToFit(mainLabel, textWidth, topFrame.height - 100);

        this.root.addChild(this._createEditName(options));
    }

    private _createEditName(options: PetNamePopupOptions) {
        const container = (this._inputContainer = new Container());
        const inputColor = 0xf1f1f1;
        const target = uiCreateQuad(inputColor, 1, 350, 70);
        const inputFrame = new NineSlicePlane(Texture.from(manifest.input), 70, 0, 70, 0);
        inputFrame.width = 450;
        inputFrame.addChild(target);
        target.x = 46;
        target.y = 24;
        container.addChild(inputFrame);

        this._input = new TextInput(app, {
            target,
            value: options.preFilledName,
            limit: 22,
            size: 3,
            focus: false,
            onUpdate: (value: any) => null,
        });

        this._confirmButton = new TextImageButton({
            text: '[buttonOk]',
            image: manifest.blueButton,
            y: -4,
            slice: {
                width: 210,
                height: 69,
                left: 45,
                top: 0,
                right: 45,
                bottom: 0,
            },
            style: {
                dropShadow: true,
                dropShadowAngle: Math.PI / 2,
                dropShadowDistance: 3,
                dropShadowAlpha: 0.7,
                dropShadowBlur: 5,
                fill: '#fff',
                fontSize: 28,
                fontWeight: 'bold',
                lineJoin: 'round',
                strokeThickness: 2,
            },
        });

        this._confirmButton.onPress = async () => {
            if (this._input.value.trim().length > 0) options.onConfirm.resolve(this._input.value.trim());
        };

        container.addChild(this._confirmButton);
        uiAlignCenter(container, this._confirmButton, 0, 110);
        return container;
    }

    private async animateIn() {
        this.base.alpha = 0;
        await this.base.animate().add(this.base, { alpha: 1 }, 0.25, tween.pow2In);
    }

    private async animateOut() {
        await this.base.animate().add(this.base, { alpha: 0 }, 0.25, tween.pow2Out);
    }
}
