import { LanguageId } from '../defs/settings';
import { timeFromComponents } from '../util/timeTools';

// constants
//-----------------------------------------------------------------------------
export default {
    name: {
        max: 40,
    },
    npc: {
        // default npc count. will pad up to this many npcs to a player/friend list
        count: 10,
    },
    person: {
        defaultImage: 'player.default.png',
    },
    support: {
        email: 'dragon-concept@play.co',
    },
    settings: {
        language: 'ja' as LanguageId,
    },
    summon: {
        daily: {
            interval: timeFromComponents({ hours: 23 }),
            reminder: timeFromComponents({ hours: 24 }),
        },
    },
};
