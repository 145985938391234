import gameConfig from '../defs/gameConfig';
import { LanguageId } from '../defs/settings';

export type ReplicantCreativeType = 'greeting' | 'firstSession0' | 'firstSession1' | 'firstSession2';

export type CreativeText = {
    id: string;
    cta: string;
    text: string;
    title: string;
};

type ReplicantMessagesConfig = {
    [lang: string]: Record<
        ReplicantCreativeType,
        {
            [bucket: string]: { title?: string; text: string; cta: string };
        }
    >;
};

const config: ReplicantMessagesConfig = {
    en: {
        greeting: {
            control: {
                text: `Thanks for adding to Friend!\n\nLet's play with a baby dragon! Please play from the below button!`,
                cta: 'Play now!',
            },
        },
        firstSession0: {
            control: {
                text: 'I-it’s so cold… the fire went out… please help…',
                cta: 'Help now!',
            },
        },
        firstSession1: {
            control: {
                text: 'Help me… I’m dying…',
                cta: 'Help now!',
            },
        },
        firstSession2: {
            control: {
                text: 'I don’t want to die alone. Please come see me one more time…',
                cta: 'Visit',
            },
        },
    },
    ja: {
        greeting: {
            control: {
                text: `友だち追加ありがとうございます！\n\n小さなドラゴンと遊びましょう！下のボタンから早速プレイしてね！`,
                cta: '今すぐプレイ！',
            },
        },
        firstSession0: {
            control: {
                text: 'さむいよ…た、たすけて…',
                cta: 'たすける！',
            },
        },
        firstSession1: {
            control: {
                text: 'たすけて…死んじゃう…',
                cta: 'たすける！',
            },
        },
        firstSession2: {
            control: {
                text: '死んじゃう前に…最後に一度だけ…',
                cta: '見に行く',
            },
        },
    },
};

// Random among text
export function getCreativeText(
    locale: LanguageId,
    type: ReplicantCreativeType,
    random: () => number,
    substitutions?: { [key: string]: any },
    titleSubstitutions?: { [key: string]: any },
): CreativeText {
    // Fallback locale if we don't have the current locale or its not translated
    const lang = config[locale] ? locale : gameConfig.settings.language;
    const messagesObj = config[lang][type];
    const messagesArr = Object.keys(messagesObj);
    const messageIdx = Math.floor(random() * messagesArr.length);
    const messageId = messagesArr[messageIdx];
    const message = messagesObj[messageId];

    let text = message.text;
    if (substitutions) {
        for (const token in substitutions) {
            text = text.replace(`{${token}}`, substitutions[token]);
        }
    }

    let title = message.title;
    if (title && titleSubstitutions) {
        for (const token in titleSubstitutions) {
            title = title.replace(`{${token}}`, titleSubstitutions[token]);
        }
    }

    return {
        id: messageId,
        cta: message.cta,
        text,
        title,
    };
}

export function getCreativeTextSpecific(
    locale: LanguageId,
    type: ReplicantCreativeType,
    subType: string,
    substitutions?: { [key: string]: any },
    titleSubstitutions?: { [key: string]: any },
): CreativeText {
    // Fallback locale if we don't have the current locale or its not translated
    const lang = config[locale] ? locale : gameConfig.settings.language;
    const messagesObj = config[lang][type];
    const message = messagesObj[subType];

    let text = message.text;
    if (substitutions) {
        for (const token in substitutions) {
            text = text.replace(`{${token}}`, substitutions[token]);
        }
    }

    let title = message.title;
    if (title && titleSubstitutions) {
        for (const token in titleSubstitutions) {
            title = title.replace(`{${token}}`, titleSubstitutions[token]);
        }
    }

    return {
        id: subType,
        cta: message.cta,
        text,
        title,
    };
}
