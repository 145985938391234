import { Container } from 'pixi.js';

import { SpineAnimation } from '../../lib/pixi/animation/SpineAnimation';

// types
//-----------------------------------------------------------------------------
// clip
export const dragonClipIds = [
    'blanket_poof',
    'eat',
    'eat_withblanket',
    'idle_happy',
    'idle_happy_blanket',
    'idle_hungry',
    'idle_injured',
    'idle_normal',
    'idle_sad',
    'idle_shiver',
    'move_happy',
    'move_happy_blanket',
    'move_normal',
    'sad_to_happy',
] as const;
export type DragonClipId = (typeof dragonClipIds)[number];

// constants
//-----------------------------------------------------------------------------
const manifest = {
    spine: 'spine.dragon.json',
};

/*
    concept: dragon
*/
export class Dragon extends Container {
    // fields
    //-------------------------------------------------------------------------
    // scene
    private _spine: SpineAnimation;

    // init
    //-------------------------------------------------------------------------
    static assets() {
        return Object.values(manifest);
    }

    constructor() {
        super();

        // spawn
        this._spawn();
    }

    // api
    //-------------------------------------------------------------------------
    public async start(id: DragonClipId, loop = false) {
        await this._spine.start({ id, loop, mix: 0.2 });
    }

    // private: scene
    //-------------------------------------------------------------------------
    private _spawn() {
        const spine = (this._spine = new SpineAnimation({ json: manifest.spine }));
        spine.scale.set(0.7);
        this.addChild(spine);

        this.pivot.set(this.width / 2, this.height / 2);
    }
}
