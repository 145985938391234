export const treatItemIds = ['blanket', 'fire', 'warmthElixir'] as const;
export const forageItemIds = ['beetle', 'mealworms', 'grubs'] as const;

export const petItemIds = [...treatItemIds, ...forageItemIds] as const;

export type PetItemId = (typeof petItemIds)[number];
export type TreatItemId = (typeof petItemIds)[number];
export type ForageItemId = (typeof petItemIds)[number];

// settings
//----------------------------------------------------------------------------
const defaultLimit = 9999;

export type ItemProps = { name: string; icon: string; limit: number };

export const petItemPropsMap: Record<PetItemId, ItemProps> = {
    fire: {
        name: '[itemPetsFire]',
        icon: `item.pets.fire.png`,
        limit: defaultLimit,
    },
    warmthElixir: {
        name: '[itemPetsWarmthElixir]',
        icon: `item.pets.warmth.elixir.png`,
        limit: defaultLimit,
    },
    blanket: {
        name: '[itemPetsBlanket]',
        icon: `item.pets.blanket.png`,
        limit: defaultLimit,
    },
    beetle: {
        name: '[itemPetsBeetle]',
        icon: `item.pets.beetle.png`,
        limit: defaultLimit,
    },
    mealworms: {
        name: '[itemPetsMealworms]',
        icon: 'item.pets.mealworms.png',
        limit: defaultLimit,
    },
    grubs: {
        name: '[itemPetsGrubs]',
        icon: 'item.pets.grubs.png',
        limit: defaultLimit,
    },
};
