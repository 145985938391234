import { Sprite } from 'pixi.js';

import app from '../../../index.entry';
import { pixiSetInterval } from '../../../lib/pixi/pixiTools';
import { ILoader } from '../../../plugins/nav/ILoader';
import { pixiConfig } from '../../defs/config';
import { LayoutScreen } from '../../lib/screens/LayoutScreen';
import { LoadingProgessBar } from '../../lib/ui/progress/LoadingProgessBar';

// constants
//-----------------------------------------------------------------------------
const manifest = {
    bg: 'bg.dragon.splash.png',
};

export let fakeProgress = 0;

/*
    nav: first thing we show
*/
export class SplashLoader extends LayoutScreen implements ILoader {
    // fields
    //-------------------------------------------------------------------------
    private _progress: LoadingProgessBar;

    // impl
    //-------------------------------------------------------------------------
    public preload() {
        return app.resource.loadAssets([...Object.values(manifest), ...LoadingProgessBar.assets()]);
    }

    public async init() {
        // progress
        const progress = (this._progress = new LoadingProgessBar({
            width: 540,
        }));

        // layout
        this.root.addContent({
            bg: {
                content: Sprite.from(manifest.bg),
                styles: {
                    position: 'center',
                    width: pixiConfig.size.width,
                    height: pixiConfig.size.height,
                    minWidth: '100%',
                    minHeight: '100%',
                },
            },
            progress: {
                content: progress,
                styles: {
                    position: 'bottomCenter',
                    scale: 0.8,
                    marginBottom: 150,
                },
            },
        });

        this._progress.setProgress((fakeProgress = 0.0), true);

        let increment = 0.2;
        pixiSetInterval(
            this.root,
            () => {
                fakeProgress += increment;
                this._progress.setProgress(fakeProgress, true);
                if (increment > 0.01) increment *= 0.3;
            },
            0.1,
        );
    }

    public async spawned(): Promise<void> {
        const splashScreenId = 'splashScreen';
        const element = document.getElementById(splashScreenId);
        if (element) {
            document.body.removeChild(element);
        }
    }
}
