import { ActiveFriendCounts, ClusterCounts } from '@play-co/gcinstant';
import { extendReplicantConfigForAnalytics } from '@play-co/gcinstant/replicantExtensions';
import { createConfig } from '@play-co/replicant';

import { asyncGetters } from './asyncGetters';
import chatbot from './chatbot/index';
import { abtestActions } from './components/abtests';
import { cheatActions } from './components/cheats';
import { coreActions } from './components/core';
import { playerActions } from './components/player';
import { settingsActions } from './components/settings';
import { tutorialActions } from './components/tutorial';
import { computedProperties } from './computedProperties';
import { messages } from './messages';
import { migrator } from './migrations';
import { abTests } from './ruleset';
import { scheduledActions } from './scheduledActions';
import { stateSchema } from './state';

// friend entry data
//-----------------------------------------------------------------------------
export type FriendsEntryData = {
    activeFriendCounts: ActiveFriendCounts;
    clusterCounts: ClusterCounts;
};

// combined actions
//-----------------------------------------------------------------------------
const actions = {
    ...abtestActions,
    ...cheatActions,
    ...coreActions,
    ...playerActions,
    ...settingsActions,
    ...tutorialActions,
};

/*
    replicant config
*/
const config = createConfig({
    appName: process.env.APP_NAME,
    actions,
    asyncGetters,
    chatbot,
    computedProperties,
    enableLineAccessTokenAuthentication: true,
    migrator,
    messages,
    scheduledActions,
    stateSchema,
    ruleset: { abTests },
    version: process.env.APP_VERSION,
});

export default extendReplicantConfigForAnalytics(config, { amplitudeTimeZone: 'Asia/Tokyo' });
