//TODO: use ImageButton instead
import { Sprite, Texture } from 'pixi.js';

import app from '../../../../index.entry';
import { BasicAsyncHandler } from '../../../../lib/defs/types';
import { TouchInputComponent } from '../../../../lib/pixi/components/TouchInputComponent';
import { onDownAnimation, onTapAnimation, onUpAnimation } from '../../util/clickAnimation';

// types
//-----------------------------------------------------------------------------
export type UpDownButtonOptions = {
    up: string;
    down?: string;
    animate: boolean;
    sound?: string;
    scrollButton?: boolean;
};

/*
    basic static image button with up/down state. if down
*/
export class UpDownButton extends Sprite {
    // fields
    //-------------------------------------------------------------------------
    // events
    public onPress?: BasicAsyncHandler;
    // input
    private _up: Texture;
    private _down: Texture;
    private _animate = false;
    private _sound: string | null;
    // components
    private _input: TouchInputComponent;

    // properties
    //-------------------------------------------------------------------------
    public set up(resource: string) {
        this._up = Texture.from(resource);
    }

    public set down(resource: string) {
        this._down = Texture.from(resource);
    }

    public set sound(sound: string) {
        this._sound = sound;
    }

    // init
    //-------------------------------------------------------------------------
    constructor(options: UpDownButtonOptions) {
        super();

        // initialize
        this.up = options.up;
        this.down = options.down || options.up;
        if (options.animate) this._animate = true;
        this._input = Object.assign(new TouchInputComponent(this), {
            onTap: this._onTap.bind(this),
            // Issue with buttons inside a scroll with pointerup event
            onDown: options.scrollButton ? null : this._onDown.bind(this),
            onUp: options.scrollButton ? null : this._onUp.bind(this),
        });

        this._sound = options.sound === null ? null : options.sound || 'button.ogg';

        // Init texture
        super.texture = this._up;
    }

    public async trigger() {
        return this._onTap();
    }

    // private: handlers
    //-------------------------------------------------------------------------
    private async _onTap() {
        if (this._animate) {
            onTapAnimation(this);
        }

        // 4 dupes to support button spam clicks
        if (this._sound) app.sound.play(this._sound, { dupes: 5, volume: 0.6 });

        // handle press
        await this.onPress?.();
    }

    private _onDown() {
        if (this._animate) {
            onDownAnimation(this);
        }

        // replace sprite with down texture
        super.texture = this._down;
    }

    private _onUp() {
        if (this._animate) {
            onUpAnimation(this);
        }
        // replace sprite with up texture
        super.texture = this._up;
    }
}
